<template>

  <client-form
    :title="title"
    :submit-button-text="submitButtonText"
    @submitClientForm="onSubmit($event)"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'
import i18n from '@/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ClientForm from './ClientForm.vue'

export default {
  components: {
    ClientForm,
  },
  data() {
    return {
      title: i18n.t('client.add.title'),
      submitButtonText: 'Ajouter',
    }
  },
  setup() {
    const toast = useToast()

    const onSubmit = formValue => store.dispatch('client/addClient', formValue)
      .then(() => {
        router.push({ name: 'clients-list' })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('client.add.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      onSubmit,
    }
  },
}
</script>
